import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { breakpoint } from 'theme';

import Layout from 'components/templates/layout';
import DotsGrid from 'components/molecules/dots-grid';
import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

import useCurrentLanguage from 'hooks/use-current-language';
import { useScreenService } from 'hooks/use-screen-service';

const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.colors.complementary1};
  padding: ${({ theme }) => `${theme.gap.md} ${theme.gap.sm}`};
  ${breakpoint.md} {
    padding: ${({ theme }) => `${theme.gap.xl} ${theme.gap.sm}`};
  }
`;

const StyledWidth = styled.div`
  margin: 0 auto;
  max-width: 726px;
`;

const StyledDotWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.wrapperXl};
  padding: ${({ theme }) => theme.gap.mdl};

  ${breakpoint.md} {
    padding: 0;
  }
`;

const StyledText = styled.h1`
  font-size: 72px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`;

const StyledDescription = styled.div`
  margin: ${({ theme }) => theme.gap.sm} 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.gap.md};
`;

const StyledDotsGridOne = styled(DotsGrid)`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
`;
const StyledDotsGridTwo = styled(DotsGrid)`
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: -15px;

  ${breakpoint.md} {
    left: auto;
    bottom: auto;
    top: 0;
    right: 0;
  }
`;

const NotFoundPage = () => {
  const { isMdUp } = useScreenService();
  const currentLanguage = useCurrentLanguage();
  const textUps = currentLanguage === 'PL' ? ' Ups, nic tu nie ma' : 'Oops, nothing here';
  const textNotExist =
    currentLanguage === 'PL'
      ? 'Ta strona nie istnieje. Mogliśmy ją usunąć lub przenieść.'
      : 'This page does not exist. We may have deleted it or moved it somewhere else.';
  const testButton =
    currentLanguage === 'PL' ? 'Wróć na stronę główną' : 'Go back to the main page';
  return (
    <Layout withoutNewsletter>
      <StyledContent>
        <StyledDotWrapper>
          <StyledDotsGridOne
            width={isMdUp ? 6 : 18}
            height={isMdUp ? 6 : 2}
            color="complementary3"
          />
          <StyledDotsGridTwo
            width={isMdUp ? 6 : 18}
            height={isMdUp ? 6 : 2}
            color="complementary3"
          />
          <StyledWidth>
            <StyledText>404</StyledText>
            <StyledDescription>
              <Typography variant="title2" component="h2" color="white">
                {textUps}
              </Typography>
              <Typography variant="title6" color="whiteAlpha72">
                {textNotExist}
              </Typography>
            </StyledDescription>
            <StyledButton
              variant="primary"
              label={testButton}
              onClick={() => {
                navigate(currentLanguage === 'PL' ? '/' : '/en/home');
              }}
            />
          </StyledWidth>
        </StyledDotWrapper>
      </StyledContent>
    </Layout>
  );
};

export default NotFoundPage;
